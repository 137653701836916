<template>
  <div class="activity-container">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "ActivityIndex",
  data() {
    return {};
  },
  methods: {},
  mounted() {}
}
</script>

<style lang="less" scoped>
.activity-container {
  width: 100%;
}
</style>
