<template>
  <div class="sleep-lottery-reward">
    <div class="reward-title">恭喜中奖啦</div>
    <div class="reward-logo" :style="{ backgroundImage: `url('${logo}')` }" />
    <div class="reward-name">获得{{ name }}</div>
    <div class="reward-form-wrap">
      <a-form-model
        id="formVirtual"
        ref="formVirtualRef"
        :model="formVirtual"
        :rules="formVirtualRules"
        class="reward-form"
        :label-col="formItemLayout.labelCol"
        :wrapper-col="formItemLayout.wrapperCol"
        v-if="type === REWARD_TYPE.VIRTUAL"
      >
        <div class="tips">
          请填写中奖信息，将在24小时内发货。
        </div>
        <a-form-model-item label="" prop="phone">
          <a-input
            size="large"
            v-model="formVirtual.phone"
            placeholder="请输入11位国内手机号"
            autocomplete="off"
            id="formVirtual_phone"
          />
        </a-form-model-item>
      </a-form-model>
      <a-form-model
        id="formEntity"
        ref="formEntityRef"
        :model="formEntity"
        :rules="formEntityRules"
        class="reward-form"
        :label-col="formItemLayout.labelCol"
        :wrapper-col="formItemLayout.wrapperCol"
        v-else
      >
        <div class="tips">
          请填写收货信息，实物奖品将在活动结束后统一发货。
        </div>
        <a-form-model-item label="" prop="name">
          <a-input
            size="large"
            v-model="formEntity.name"
            placeholder="请输入收货人姓名"
            autocomplete="off"
            id="formEntity_name"
          />
        </a-form-model-item>
        <a-form-model-item label="" prop="phone">
          <a-input
            size="large"
            v-model="formEntity.phone"
            placeholder="请输入收货人手机号码"
            autocomplete="off"
          />
        </a-form-model-item>
        <a-form-model-item label="" prop="area">
          <EcArea
            v-model="formEntity.area"
            placeholder="请输入收货人所在地区"
          />
        </a-form-model-item>
        <a-form-model-item label="" prop="addr">
          <a-input
            size="large"
            v-model="formEntity.addr"
            placeholder="收货人详细地址"
            autocomplete="off"
          />
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="reward-foot">
      <a-button
        type="primary"
        class="lottery-btn reward-submit"
        @click="onSubmit"
      >
        确认提交
      </a-button>
      <!--<a-button
        type="link"
        class="lottery-btn reward-close"
        @click="onClose"
      >
        稍后领取
      </a-button>-->
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import { FETCH_CODE, REG_MOBILE } from "@/config";
import { REWARD_TYPE } from "@/pages/activity/SleepLottery/config";
import EcArea from "@/components/EcArea.vue";
import { showMsg } from "@/utils";
import { redeem } from "@/pages/activity/SleepLottery/api";
import { mapGetters } from "vuex";

const EmptyVirtual = {
  phone: ""
};
const EmptyEntity = {
  name: "",
  phone: "",
  area: [],
  addr: ""
};

export default {
  name: "SleepLotteryReward",
  components: {
    EcArea
  },
  computed: {
    ...mapGetters("user", ["sleepLotteryActivityId"]),
    REWARD_TYPE() {
      return REWARD_TYPE;
    }
  },
  props: {
    activityId: String,
    type: Number,
    name: String,
    logo: String,
    drawId: String,
    prizeId: String
  },
  data() {
    return {
      formItemLayout: {
        labelCol: { span: 0 },
        wrapperCol: { span: 24 }
      },
      formVirtual: cloneDeep(EmptyVirtual),
      formVirtualRules: {
        phone: [
          { required: true, message: "请输入手机号" },
          { pattern: REG_MOBILE, message: "请输入11位国内手机号" }
        ]
      },
      formEntity: cloneDeep(EmptyEntity),
      formEntityRules: {
        name: [
          { required: true, message: "请输入姓名" },
          { max: 50, message: "姓名不能超过50个字符" }
        ],
        phone: [
          { required: true, message: "请输入手机号" },
          { pattern: REG_MOBILE, message: "请输入11位国内手机号" }
        ],
        area: [{ required: true, message: "请输入所在地区" }],
        addr: [
          { required: true, message: "请输入详细地址" },
          { max: 255, message: "详细地址不能超过255个字符" }
        ]
      },
      loading: false
    };
  },
  methods: {
    onSubmit() {
      if (this.loading) return;
      this.loading = true;
      // 实物
      if (this.type === REWARD_TYPE.ENTITY) {
        this.$refs.formEntityRef.validate(async (valid, errors) => {
          if (!valid) {
            showMsg({
              flag: FETCH_CODE.WARNING.KEY,
              msg: Object.values(errors)[0]?.[0]?.message
            });
            this.loading = false;
            return;
          }
          const res = await redeem({
            activityId: this.activityId,
            mobileNumber: this.formEntity.phone,
            addressProvince: this.formEntity.area[0],
            addressCity: this.formEntity.area[1],
            addressDistrict: this.formEntity.area[2],
            addressDetail: this.formEntity.addr,
            name: this.formEntity.name,
            prizeId: this.prizeId,
            drawId: this.drawId
          });
          if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
            showMsg(res);
            this.loading = false;
            return;
          }
          showMsg({
            flag: FETCH_CODE.SUCCESS.KEY,
            msg: "提交成功！"
          });
          this.$store.dispatch("user/updateOrderStatus", {
            activityId: this.sleepLotteryActivityId
          });
          this.onClose();
        });
        return;
      }
      // 虚拟物品
      this.$refs.formVirtualRef.validate(async (valid, errors) => {
        if (!valid) {
          showMsg({
            flag: FETCH_CODE.WARNING.KEY,
            msg: Object.values(errors)[0]?.[0]?.message
          });
          this.loading = false;
          return;
        }
        const res = await redeem({
          activityId: this.activityId,
          mobileNumber: this.formVirtual.phone,
          prizeId: this.prizeId,
          drawId: this.drawId
        });
        if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
          showMsg(res);
          this.loading = false;
          return;
        }
        showMsg({
          flag: FETCH_CODE.SUCCESS.KEY,
          msg: "提交成功！"
        });
        this.$store.dispatch("user/updateOrderStatus", {
          activityId: this.sleepLotteryActivityId
        });
        this.onClose();
      });
    },
    onClose() {
      this.$emit("close");
    }
  },
  mounted() {}
};
</script>

<style lang="less" scoped>
@import "../../index";

.sleep-lottery-reward {
  padding-top: 15px;
  padding-bottom: 15px;

  .reward-title {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    color: @lottery-primary-color;
  }
  .reward-logo {
    width: 100px;
    height: 100px;
    border: 0 solid #dddddd;
    margin: 15px auto;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
  .reward-name {
    margin-top: 15px;
    text-align: center;
    font-size: 16px;
  }
  .reward-form-wrap {
    margin-top: 30px;
  }
  .reward-form {
    .tips {
      font-size: 14px;
      color: @desc-color;
      text-align: center;
      margin-bottom: 30px;
    }
  }
  .reward-foot {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 5px;
  }
  .reward-submit {
    width: 100%;
  }
}
</style>
