<template>
  <div class="sleep-lottery">
    <a-spin class="loading-wrapper" :spinning="loading">
      <a-icon slot="indicator" type="loading" class="loading-icon" spin />
      <div class="sleep-lottery-inner">
        <div class="sleep-lottery-col sleep-left">
          <a-card class="sleep-lottery-card task-record-list" title="任务列表" :bordered="false">
            <a-row class="card-list">
              <a-col :span="24" class="card-list-title">
                单次任务
              </a-col>
              <a-col :span="24" class="card-list-content">
                <a-skeleton
                  :loading="loading"
                  :title="false"
                  active
                  :paragraph="{ rows: 1 }"
                >
                  <div
                    class="card-list-item"
                    v-for="task in singleTask"
                    :key="task.description"
                  >
                    <div class="item-title">
                      {{ task.description }}
                    </div>
                    <div class="item-status">
                      已完成
                    </div>
                    <div class="item-reward">+{{ task.pointsOnce }}积分</div>
                  </div>
                </a-skeleton>
              </a-col>
            </a-row>
            <a-row class="card-list">
              <a-col :span="24" class="card-list-title">
                背调任务
                <a-icon
                  type="exclamation-circle"
                  class="card-list-tips"
                  theme="filled"
                  @click="onDesc"
                />
              </a-col>
              <a-col :span="24" class="card-list-content">
                <a-skeleton
                  :loading="loading"
                  :title="false"
                  active
                  :paragraph="{ rows: 2 }"
                >
                  <div
                    class="card-list-item"
                    v-for="task in backCheckTask"
                    :key="task.description"
                  >
                    <div class="item-title">
                      {{ task.description }}
                    </div>
                    <div class="item-status"></div>
                    <div class="item-reward">
                      +{{ task.pointsMin }}~{{ task.pointsMax }}积分
                    </div>
                  </div>
                </a-skeleton>
              </a-col>
            </a-row>
            <a-button
              size="small"
              type="primary"
              class="lottery-btn points-record"
              ghost
              @click="onRecord"
            >
              积分记录
            </a-button>
          </a-card>
          <a-card
            class="sleep-lottery-card reward-record-list"
            title="奖品记录"
            :bordered="false"
          >
            <a-skeleton
              :loading="loading || rewardListLoading"
              :title="false"
              active
              :paragraph="{ rows: 5 }"
            >
              <div
                class="reward-item"
                v-for="(it, idx) in rewardList"
                :key="idx"
              >
                <div class="reward-item-left">
                  <div class="reward-item-title">
                    {{ it.name }}
                  </div>
                  <div class="reward-item-time">
                    {{ it.winningTime }}
                  </div>
                </div>
                <div class="reward-item-right">
                  <a-button
                    size="small"
                    :type="it.exchanged ? 'link' : 'primary'"
                    class="lottery-btn"
                    @click="onReward(it)"
                    :disabled="it.exchanged"
                  >
                    {{ it.exchanged ? "已领取" : "领取" }}
                  </a-button>
                </div>
              </div>
              <div class="reward-item empty" v-if="!rewardList.length">
                暂无中奖记录
              </div>
            </a-skeleton>
          </a-card>
        </div>
        <div class="sleep-lottery-col sleep-center">
          <h1 class="sleep-lottery-title">
            <img
              src="@/assets/images/activity/sleep-lottery/lottery-title.png"
              alt="下单赢好礼 百分百中将"
            />
          </h1>
          <div class="sleep-lottery-tabs">
            <a
              href="javascript: void(0);"
              class="tab-item"
              v-for="tab in tabsData"
              :key="tab.key"
              :class="activeTabKey === tab.key ? 'active' : ''"
              @click="onTab(tab)"
            >
              {{ tab.name }}
            </a>
          </div>
          <div class="sleep-lottery-player">
            <template v-for="tab in tabsData">
              <div
                :key="tab.key"
                class="sleep-lottery-player-wheel"
                :style="{
                  transform: `rotate(-${tab.rotate}deg)`,
                  transitionDuration: `${animateDuration}s`,
                  backgroundImage: `url('${tab.wheel}')`
                }"
                v-show="tab.key === activeTabKey"
              />
            </template>
            <a
              href="javascript: void(0);"
              class="sleep-lottery-player-pointer"
              @click="onStart"
            ></a>
          </div>
          <div class="sleep-lottery-player-balance">
            <span>剩余积分：{{ balancePoints }}</span>
            <a-icon
              class="balance-refresh"
              :class="balancePointsLoading ? 'disabled' : ''"
              title="刷新"
              type="redo"
              @click="getBalancePoints"
            />
          </div>
          <div class="sleep-lottery-player-tips">
            <p v-for="pool in activityData.prizePool" :key="pool.id">
              {{ pool.name }}奖池抽奖一次消耗{{ pool.points }}积分
            </p>
          </div>
          <div class="sleep-lottery-player-announcement-wrap">
            <vue-seamless-scroll
              :data="announcementList"
              class="sleep-lottery-player-announcement"
            >
              <ul class="item">
                <li v-for="(item, index) in announcementList" :key="index">
                  <span class="title" v-text="item.title"></span>
                </li>
              </ul>
            </vue-seamless-scroll>
          </div>
        </div>
        <div class="sleep-lottery-col sleep-right">
          <a-card class="sleep-lottery-card" title="活动规则" :bordered="false">
            <div class="rule-item">
              <h3 class="rule-title">
                活动时间
              </h3>
              <div class="rule-content">北京时间{{ activityTime }}</div>
            </div>
            <div class="rule-item">
              <h3 class="rule-title">
                活动对象
              </h3>
              <div class="rule-content">
                <a-skeleton
                  :loading="loading"
                  :title="false"
                  active
                  :paragraph="{ rows: 5 }"
                >
                  {{ activityData.activityDescriptionUserRange }}
                </a-skeleton>
              </div>
            </div>
            <div class="rule-item">
              <h3 class="rule-title">
                参与方式
              </h3>
              <div class="rule-content">
                <a-skeleton
                  :loading="loading"
                  :title="false"
                  active
                  :paragraph="{ rows: 2 }"
                >
                  {{ activityData.activityDescriptionJoin }}
                </a-skeleton>
              </div>
            </div>
            <div class="rule-item">
              <h3 class="rule-title">
                奖池介绍
              </h3>
              <div class="rule-content">
                <div v-for="pool of activityData.prizePool" :key="pool.id">
                  <div>
                    {{ pool.name }}奖池抽奖一次消耗{{
                      pool.points
                    }}积分，奖品如下：
                  </div>
                  <a-list
                    class="reward-list"
                    bordered
                    :data-source="pool.prizeList"
                  >
                    <a-list-item slot="renderItem" slot-scope="item">
                      {{ item.name }}
                    </a-list-item>
                    <div slot="header">
                      奖品
                    </div>
                  </a-list>
                </div>
              </div>
            </div>
            <div class="rule-item">
              <h3 class="rule-title">
                活动玩法
              </h3>
              <div class="rule-content">
                <a-skeleton
                  :loading="loading"
                  :title="false"
                  active
                  :paragraph="{ rows: 5 }"
                >
                  <div v-html="activityData.activityDescriptionRule"></div>
                </a-skeleton>
              </div>
            </div>
            <div class="rule-item">
              <h3 class="rule-title">
                温馨提醒
              </h3>
              <div class="rule-content">
                <a-skeleton
                  :loading="loading"
                  :title="false"
                  active
                  :paragraph="{ rows: 5 }"
                >
                  <div v-html="activityData.activityDescriptionGentleReminder"></div>
                </a-skeleton>
              </div>
            </div>
            <div class="rule-foot">
              本活动最终解释权归上海简核信息科技有限公司所有。
            </div>
          </a-card>
        </div>
      </div>
      <SleepLotteryRewardModal
        :visible.sync="rewardVisible"
        :type="currentReward.type"
        :name="currentReward.name"
        :logo="currentReward.logo"
        :prizeId="currentReward.prizeId"
        :drawId="currentReward.drawId"
        :activityId="activityData.activityId"
        @close="onRewardModalClose"
      />
      <SleepLotteryRecordModal
        :visible.sync="recordVisible"
        :activityId="activityData.activityId"
      />
      <SleepLotteryDescModal
        :visible.sync="descVisible"
        :activityId="activityData.activityId"
      />
    </a-spin>
  </div>
</template>

<script>
import VueSeamlessScroll from "vue-seamless-scroll";
import SleepLotteryRewardModal from "./components/Reward/Modal.vue";
import SleepLotteryRecordModal from "./components/Record/Modal.vue";
import SleepLotteryDescModal from "./components/Description/Modal.vue";
import { orderBy, random, union } from "lodash";
import { showMsg } from "@/utils";
import {
  announcementData,
  TASK_TYPE
} from "@/pages/activity/SleepLottery/config";
import {
  awards,
  info,
  prize,
  remaining,
  start
} from "@/pages/activity/SleepLottery/api";
import { FETCH_CODE } from "@/config";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "SleepLottery",
  components: {
    VueSeamlessScroll,
    SleepLotteryRewardModal,
    SleepLotteryRecordModal,
    SleepLotteryDescModal
  },
  data() {
    return {
      // 页面加载中
      loading: false,
      // 动画旋转时长
      animateDuration: 10,
      // 抽奖中
      pending: false,
      // 抽奖计时器
      timer: 0,
      // 奖池类别
      tabsData: [],
      // 当前奖池
      activeTabKey: null,
      rewardVisible: false,
      recordVisible: false,
      descVisible: false,
      // 中奖数据
      currentReward: {},
      // 本人中奖记录
      rewardList: [],
      rewardListLoading: false,
      // 剩余积分
      balancePoints: 0,
      balancePointsLoading: false,
      // 实时中奖记录
      announcementList: [],
      // 活动信息
      activityData: {
        activityDescriptionJoin: "",
        activityDescriptionRule: "",
        activityDescriptionGentleReminder: "",
        activityDescriptionUserRange: "",
        activityId: null,
        activityInProgress: false,
        beginTime: "",
        endTime: "",
        prizePool: [],
        task: []
      },
      // 轮询实时中奖记录
      announcementListTimer: 0,
      // 轮询间隔 秒
      announcementListDuration: 60
    };
  },
  computed: {
    ...mapGetters("user", ["sleepLotteryActivityId"]),
    activeTab() {
      return this.tabsData.find(it => it.key === this.activeTabKey) || {};
    },
    activeTabIndex() {
      return this.tabsData.findIndex(it => it.key === this.activeTabKey) || 0;
    },
    activityTime() {
      const formatter = "YYYY年MM月DD HH:mm:ss";
      const beginTime = moment(this.activityData.beginTime).format(formatter);
      const endTime = moment(this.activityData.endTime).format(formatter);
      return `${beginTime} ~ ${endTime}`;
    },
    singleTask() {
      return this.activityData.task.filter(it => {
        return it.type === TASK_TYPE.SINGLE;
      });
    },
    backCheckTask() {
      return this.activityData.task.filter(it => {
        return it.type === TASK_TYPE.BACKGROUND_CHECK;
      });
    }
  },
  methods: {
    async onStart() {
      if (this.pending) {
        showMsg({
          flag: 1,
          msg: "请等待当前抽奖完毕后再试！"
        });
        return;
      }
      this.pending = true;
      const res = await start({
        activityId: this.activityData.activityId,
        poolId: this.activeTabKey
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        showMsg(res);
        this.pending = false;
        return;
      }
      // 获取剩余积分
      this.balancePoints = res.data.remainingPoints;
      // 抽奖动画
      const reward = res.data.prizeId;
      let _rotate = this.activeTab.rotate;
      const rewards = this.activeTab.rewards || [];
      // 边缘内缩度数
      const offset = 5;
      // 获取奖品下标
      const rewardIndex = rewards.findIndex(it => it.id === reward);
      // 计算奖品所在区间
      const piece = 360 / rewards.length;
      const randomRotate = random(
        piece * rewardIndex - piece / 2 + offset,
        piece * (rewardIndex + 1) - piece / 2 - offset
      );
      // 先补全上次的渲染到0度
      _rotate += 360 - (_rotate % 360);
      // 加上20圈固定度数
      _rotate += 360 * 20;
      // 加上这次的度数
      _rotate += randomRotate;
      // 旋转动画
      this.$set(this.tabsData, this.activeTabIndex, {
        ...this.tabsData[this.activeTabIndex],
        rotate: _rotate
      });
      this.timer = setTimeout(() => {
        this.pending = false;
        clearTimeout(this.timer);
        this.onReward({
          type: res.data.prizeType,
          name: res.data.prizeName,
          logo: res.data.prizeLogo,
          drawId: res.data.drawId,
          prizeId: res.data.prizeId
        });
        // 获取中奖记录
        this.getRewardList();
        // 获取实时中奖记录
        this.getAnnouncementList();
        // 轮询重置
        this.startAnnouncementListTimer();
      }, this.animateDuration * 1000);
    },
    onTab(tab) {
      if (this.pending) {
        showMsg({
          flag: 1,
          msg: "请等待当前抽奖完毕后再试！"
        });
        return;
      }
      if (tab.key === this.activeTabKey) return;
      this.activeTabKey = tab.key;
    },
    onReward(item) {
      this.rewardVisible = true;
      this.currentReward = item;
    },
    onRecord() {
      this.recordVisible = true;
    },
    onDesc() {
      this.descVisible = true;
    },
    async getRewardList() {
      const res = await prize({
        activityId: this.activityData.activityId
      });
      const rewardList = res.data || [];
      let allList = [];
      for (const pool of this.activityData.prizePool) {
        allList = union(allList, pool.prizeList);
      }
      rewardList.forEach(item => {
        const curr = allList.find(it => it.id === item.prizeId);
        if (!curr) return;
        item.logo = curr.logo;
      });
      this.rewardList = orderBy(rewardList, "winningTime", "desc");
    },
    async getAnnouncementList() {
      const latestLen = 30;
      const res = await awards({
        activityId: this.activityData.activityId
      });
      const announcementList = res.data || [];
      // 中奖记录长度不足 自动补全固定数据
      if (announcementList.length < latestLen) {
        announcementData
          .slice(0, latestLen - announcementList.length)
          .forEach(it => {
            announcementList.push(it);
          });
      }
      announcementList.forEach(it => {
        it.title = `用户${it.mobileNumber}抽奖了${it.prizeName}`;
      });
      this.announcementList = announcementList;
    },
    async getBalancePoints() {
      if (this.balancePointsLoading) return;
      this.balancePointsLoading = true;
      const res = await remaining({
        activityId: this.activityData.activityId
      });
      this.balancePoints = res.data || 0;
      this.balancePointsLoading = false;
      this.$store.dispatch("user/updateOrderStatus", {
        activityId: this.sleepLotteryActivityId
      });
    },
    async getActivityData(activityId) {
      const res = await info({
        activityId
      });
      if (res.flag !== FETCH_CODE.SUCCESS.KEY) {
        await this.closePage(res.msg);
        return;
      }
      this.activityData = {
        ...res.data,
        activityId: res.data.activityId
      };
      // 显示奖池
      const { prizePool = [] } = res.data;
      const tabsData = [];
      for (const pool of prizePool) {
        tabsData.push({
          key: pool.id,
          name: `${pool.name}奖池`,
          rotate: 0,
          wheel: pool.logo,
          rewards: pool.prizeList
        });
      }
      this.tabsData = tabsData;
      this.activeTabKey = tabsData[0]?.key;
    },
    async closePage(msg) {
      await showMsg({
        flag: 2,
        msg: msg
      });
      window.close();
    },
    startAnnouncementListTimer() {
      clearInterval(this.announcementListTimer);
      this.announcementListTimer = setInterval(() => {
        this.getAnnouncementList();
      }, this.announcementListDuration * 1000);
    },
    onRewardModalClose() {
      // 获取中奖记录
      this.getRewardList();
      // 轮询重置
      this.startAnnouncementListTimer();
    }
  },
  async mounted() {
    this.loading = true;
    if (!this.sleepLotteryActivityId) {
      await this.closePage("当前活动不存在！");
      this.loading = false;
      return;
    }
    this.$store.dispatch("user/updateOrderStatus", {
      activityId: this.sleepLotteryActivityId
    });
    // 获取活动基本信息
    this.getActivityData(this.sleepLotteryActivityId).then(async () => {
      if (!this.activityData.activityInProgress) {
        await this.closePage("当前活动已过期！");
        this.loading = false;
        return;
      }
      // 获取中奖记录
      this.rewardListLoading = true;
      this.getRewardList().then(() => {
        this.rewardListLoading = false;
      });
      // 获取剩余积分
      this.getBalancePoints();
      // 获取实时中奖记录
      this.getAnnouncementList().then(() => {
        this.startAnnouncementListTimer();
      });
      this.loading = false;
    });
  },
  beforeDestroy() {
    clearInterval(this.announcementListTimer);
  }
};
</script>

<style lang="less" scoped>
@import "./index";

@side-width: 350px;
@content-width: 1200px;
@col-gutter: 15px;

.col-card(@height: 100%) {
  /deep/.sleep-lottery-card {
    height: @height;
    .ant-card-body {
      height: calc(100% - 57px);
      overflow: auto;
    }
  }
}

.sleep-lottery {
  width: 100%;
  background: #efefef;
  min-width: max-content;

  .loading-icon {
    color: @lottery-primary-color;
    font-size: 24px;
  }
  &-inner {
    width: @content-width;
    margin: 0 auto;
    padding: @col-gutter;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: nowrap;
  }
  &-col {
    &.sleep-left,
    &.sleep-right {
      flex-basis: @side-width;
      flex-shrink: 0;
      flex-grow: 0;
      width: @side-width;
      height: calc(100vh - @col-gutter * 2);
      position: sticky;
      top: 15px;
      overflow: hidden;
      .col-card(300px);
    }
    &.sleep-left {
      /deep/.sleep-lottery-card.reward-record-list {
        height: 450px;
      }
    }
    &.sleep-center {
      flex: 1;
      width: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
    &.sleep-right {
      .col-card;
    }
  }
  &-card {
    /deep/.ant-card-head-title {
      font-weight: bold;
      color: @lottery-primary-color;
    }
    &.reward-record-list {
      margin-top: @col-gutter;
    }
    .card-list {
      margin-bottom: 15px;
      &-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
      }
      &-content {
        margin-top: 5px;
      }
      &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .item-title {
        }
        .item-status {
        }
        .item-reward {
        }
      }
      &-tips {
        color: @warning-color;
        margin-left: 4px;
        font-size: 16px;
        cursor: pointer;
      }
    }
    .reward-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0;
      border-bottom: 1px solid #ddd;
      &:last-child {
        border-bottom: 0;
      }
      &.empty {
        display: flex;
        justify-content: center;
        color: @sub-color;
        border-bottom: 0;
        padding-top: 30px;
      }
      &-left {
      }
      &-title {
      }
      &-time {
      }
      &-right {
      }
    }
    .rule-item {
      margin-bottom: 10px;
      .rule-title {
        font-size: 14px;
        font-weight: 600;
        margin: 0;
      }
      .rule-content {
        font-size: 14px;
        margin-top: 5px;
        /deep/ul {
          list-style: disc;
          padding-left: 15px;
        }
      }
    }
    .reward-list {
      margin-top: 10px;
      margin-bottom: 15px;
      /deep/.ant-list-header {
        background: #dddddd;
      }
    }
    .rule-foot {
      margin-top: 30px;
      font-size: 12px;
    }
    .points-record {
      margin-top: 15px;
      margin-left: 50%;
      transform: translateX(-50%);
    }
  }
  &-title {
    width: 440px;
    height: 175px;
    margin: 0;
    display: flex;
    justify-content: center;
    img {
      display: block;
      width: 100%;
    }
  }
  &-tabs {
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 45px;
    height: 50px;
    .tab-item {
      width: 200px;
      height: 100%;
      border: 1px solid @lottery-primary-color;
      text-align: center;
      line-height: 48px;
      border-radius: 4px;
      color: @lottery-primary-color;
      transition: all 0.3s ease-in;
      &:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      &:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      &.active {
        background: @lottery-primary-color;
        color: #ffffff;
      }
    }
  }
  &-player {
    position: relative;
    width: 400px;
    height: 400px;
    margin-top: 30px;
    &-wheel {
      width: 400px;
      height: 400px;
      border-radius: 50%;
      border: 1px solid #dddddd;
      transition: transform cubic-bezier(0.06, 0.35, 0, 1) 10s;
      text-align: center;
      line-height: 398px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    &-pointer {
      width: 150px;
      height: 183px;
      position: absolute;
      z-index: 1;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: url("~@/assets/images/activity/sleep-lottery/lottery-wheel-pointer.png")
        no-repeat center / cover;
    }
    &-balance {
      margin-top: 20px;
      text-align: center;
      background: #ffffff;
      border-radius: 4px;
      padding: 10px 56px;
      font-size: 16px;
      position: relative;
      .balance-refresh {
        color: @lottery-primary-color;
        cursor: pointer;
        position: absolute;
        z-index: 1;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        &.disabled {
          color: @disabled-color;
          cursor: not-allowed;
        }
      }
    }
    &-tips {
      margin-top: 20px;
      p {
        margin: 0;
      }
    }
    &-announcement {
      &-wrap {
        width: 350px;
        height: 120px;
        margin-top: 20px;
        background: @lottery-primary-color;
        padding: 10px;
        border-radius: 4px;
      }
      height: 100%;
      overflow: hidden;
      ul {
        list-style: none;
        padding: 0;
        margin: 0 auto;
        li,
        a {
          height: 30px;
          line-height: 30px;
          display: flex;
          justify-content: space-between;
          font-size: 15px;
          color: #ffffff;
        }
        li .title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

@media screen and (max-height: 810px) {
  .sleep-lottery {
    &-col {
      &.sleep-left {
        /deep/.sleep-lottery-card.reward-record-list {
          height: calc(100% - 300px - @col-gutter);
        }
      }
    }
  }
}
</style>
