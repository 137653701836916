// 奖品类型
export const REWARD_TYPE = {
  // 虚拟奖品
  VIRTUAL: 1,
  // 实物奖品
  ENTITY: 2
};

// 任务类型
export const TASK_TYPE = {
  // 单次任务
  SINGLE: 1,
  // 背调任务
  BACKGROUND_CHECK: 2,
};

// 中奖记录固定数据
export const announcementData = [
  { mobileNumber: "135****6542", prizeName: "知乎盐选会员月卡" },
  { mobileNumber: "178****2787", prizeName: "美团5元红包" },
  { mobileNumber: "178****5629", prizeName: "饿了么5元红包" },
  { mobileNumber: "132****3477", prizeName: "美团5元红包" },
  { mobileNumber: "132****5141", prizeName: "苗阿妈茶树菇 76g" },
  { mobileNumber: "170****8408", prizeName: "滴滴快车5元代金券" },
  { mobileNumber: "133****2269", prizeName: "肯德基新奥尔良烤翅+九珍果汁" },
  { mobileNumber: "135****8954", prizeName: "网易云音乐周卡" },
  { mobileNumber: "184****5913", prizeName: "滴滴快车5元代金券" },
  { mobileNumber: "185****7411", prizeName: "饿了么5元红包" }
];
