<template>
  <a-modal
    title="积分记录"
    :visible="visible"
    @cancel="onCancel"
    @ok="onOk"
    class="data-modal"
    :width="650"
    :maskClosable="false"
    destroyOnClose
    :footer="null"
  >
    <Index :activityId="activityId" />
  </a-modal>
</template>

<script>
import Index from "./Index.vue";

export default {
  name: "SleepLotteryRecordModal",
  components: { Index },
  props: {
    visible: Boolean,
    record: {
      type: Object,
      default: () => {}
    },
    activityId: String,
  },
  data() {
    return {};
  },
  methods: {
    onCancel() {
      this.$emit("update:visible", false);
    },
    onOk() {
      this.onCancel();
    }
  },
  created() {}
};
</script>

<style lang="less" scoped></style>
