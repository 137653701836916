<template>
  <a-cascader
    :value="value"
    class="ec-area-selector"
    :options="areaTree"
    :placeholder="placeholder"
    @change="onChange"
    :load-data="onLoad"
  />
</template>

<script>
import api from "@/utils/fetch";

export default {
  name: "EcArea",
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    placeholder: String,
    value: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      areaTree: []
    };
  },
  methods: {
    onChange(value) {
      this.$emit("change", value);
    },
    onLoad(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      getArea({
        parentCode: targetOption.value
      }).then((data) => {
        targetOption.children = data;
        targetOption.loading = false;
        this.areaTree = [...this.areaTree];
      });
    },
    async getProvince() {
      this.areaTree = await getArea();
    }
  },
  mounted() {
    this.getProvince();
  }
}

/**
 * 省市区接口（parentCode传空获取所有省份，传省份获取所有市区）
 * @param {Object=} params
 * @param {string=} params.parentCode
 * @return {Promise<{flag: (0|1|2), msg: string, data: *, xhr: XMLHttpRequest}>}
 */
async function getArea(params) {
  const res = await api.get("/sys/area/list", params);
  const data = res.data || [];
  data.forEach((it) => {
    it.value = it.code;
    it.label = it.name;
    it.isLeaf = it.level > 2;
  });
  return data;
}
</script>

<style lang="less" scoped></style>
