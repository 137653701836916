<template>
  <a-modal
    title=""
    :visible="visible"
    @cancel="onCancel"
    @ok="onOk"
    class="data-modal"
    :width="450"
    :maskClosable="false"
    destroyOnClose
    :closable="false"
    :footer="null"
  >
    <Index
      @close="onCancel"
      :type="type"
      :name="name"
      :logo="logo"
      :prizeId="prizeId"
      :drawId="drawId"
      :activityId="activityId"
    />
  </a-modal>
</template>

<script>
import Index from "./Index.vue";

export default {
  name: "SleepLotteryRewardModal",
  components: { Index },
  props: {
    visible: Boolean,
    type: Number,
    name: String,
    logo: String,
    prizeId: String,
    drawId: String,
    activityId: String,
  },
  data() {
    return {};
  },
  methods: {
    onCancel() {
      this.$emit("update:visible", false);
      this.$emit("close");
    },
    onOk() {
      this.onCancel();
    }
  },
  created() {}
};
</script>

<style lang="less" scoped></style>
