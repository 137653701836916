<template>
  <ul class="sleep-lottery-record">
    <a-skeleton
      :loading="loading"
      :title="false"
      active
      :paragraph="{ rows: 5 }"
    >
      <li class="record-item" v-for="item in recordData" :key="item.key">
        <div class="record-left">
          <div class="record-name">
            {{ item.description }}
          </div>
          <div class="record-time">{{ item.date }}</div>
        </div>
        <div class="record-right" :class="item.pointsChange > 0 ? 'plus' : ''">
          {{
            item.pointsChange > 0 ? `+${item.pointsChange}` : item.pointsChange
          }}
        </div>
      </li>
      <li class="record-item none" v-if="!recordData.length">
        暂无数据
      </li>
      <li class="load-more" v-else>
        <div class="no-more">
          —— 没有更多数据了 ——
        </div>
      </li>
    </a-skeleton>
  </ul>
</template>

<script>
import { points } from "@/pages/activity/SleepLottery/api";
import { orderBy } from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "SleepLotteryRecord",
  props: {
    activityId: String
  },
  data() {
    return {
      recordData: [],
      loading: false
    };
  },
  computed: {
    ...mapGetters("user", ["sleepLotteryActivityId"]),
  },
  methods: {
    onMore() {},
    async getRecordData() {
      const res = await points({
        activityId: this.activityId
      });
      const recordData = res.data || [];
      this.recordData = orderBy(recordData, "date", "desc");
      this.$store.dispatch("user/updateOrderStatus", {
        activityId: this.sleepLotteryActivityId
      });
    }
  },
  mounted() {
    this.loading = true;
    this.getRecordData().then(() => {
      this.loading = false;
    });
  }
};
</script>

<style lang="less" scoped>
@import "../../index";

.sleep-lottery-record {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  padding-bottom: 30px;
  max-height: 500px;
  overflow: auto;
  .record-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    gap: 15px;
    border-bottom: 1px solid #dddddd;
    padding: 10px 15px;
    &:last-child {
      border-bottom: 0;
    }
    &.none {
      font-size: 14px;
      color: @desc-color;
      text-align: center;
      padding: 30px 15px;
      display: flex;
      justify-content: center;
    }
  }
  .record-left {
    flex: 1;
    width: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
    gap: 5px;
  }
  .record-right {
    width: 90px;
    flex-basis: 90px;
    flex-shrink: 0;
    flex-grow: 0;
    font-size: 18px;
    font-weight: bold;
    text-align: right;
    color: @success-color;
    &.plus {
      color: @error-color;
    }
  }
  .record-name {
    font-size: 16px;
    width: 100%;
    .single-hidden;
  }
  .record-time {
    font-size: 14px;
    color: @desc-color;
  }
  .load-more {
    display: flex;
    justify-content: center;
    padding-top: 30px;
  }
  .no-more {
    color: @desc-color;
    font-size: 12px;
    display: flex;
    justify-content: center;
  }
}
</style>
