<template>
  <article class="sleep-lottery-desc">
    <p>
      <strong>登录 ehr 新系统：</strong>
      活动期间登录 ehr 系统即可
    </p>
    <p>
      <strong>背调报告开始制作：</strong>
      活动期间通过新系统（域名为：{{
        url
      }}）下单的账号所属公司的委托，若在活动期间能够将任务进度进展为“报告制作”，可获得积分。
    </p>
    <p>
      <strong>背调报告完成：</strong>
      活动期间通过新系统（域名为：{{
        url
      }}）下单的账号所属公司的委托，若在活动期间能出具阶段报告或终版报告，均可以获得积分（一单委托仅在首次出报告时可获得积分）。
    </p>
    <br />
    <br />
    <p>
      发放积分数量依据用户委托下单的模板包含的模块及所属类型而定，按照模块的一定比例计算，且仅计取整数向上取整。
    </p>
    <p>
      <strong>说明：</strong>
    </p>
    <p>
      <strong>工作类模块</strong
      >包含：工作履历核实、工作表现鉴定（主观评价版）、工作表现鉴定（行为特征版）、海外工作履历核实、工作表现鉴定（决策版）、深度工作模块。
    </p>
    <p><strong>非工作类模块：</strong>指所有除工作类模块外的其他模块。</p>
    <br />
    <br />
    <p>
      模块属于非工作类时，积分数量为非工作模块数量的50%；
    </p>
    <p>
      模块属于工作类时，积分数量为工作类模块的 200%；
    </p>
    <p>
      用户获得的积分为上述两类模块积分之和。
    </p>
    <br />
    <br />
    <p>
      例如，用户下单模板包含【个人身份基础信息核实、网贷逾期、有限诉讼记录查询、失信风险检索、公共风险、工作履历
      1 段、工作表现 1
      段】，那么用户获得积分为（5个非工作模块）*50%+（2个工作模块）*200%
    </p>
    <p>
      5 * 50% + 2 * 200% = 2.5（向上取整=3）+ 4 = 7 积分
    </p>
  </article>
</template>

<script>
export default {
  name: "SleepLotteryDesc",
  props: {},
  data() {
    return {
      url: "ehr.51echeck.com"
    };
  },
  methods: {},
  mounted() {}
};
</script>

<style lang="less" scoped>
.sleep-lottery-desc {
  padding-bottom: 30px;
  p {
    font-size: 16px;
    margin: 0;
  }
}
</style>
